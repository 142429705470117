const CONST_CASASDEC_VALORUNITARIO: number = 4;
var CASASDEC_VALORUNITARIO: number = 4;

function setPubUsuario(usuario) {
    localStorage.setItem('usuario', usuario);
}

function getPubUsuario(): string {
    return localStorage.getItem('usuario');
}

function setPubEmpresa(empresa) {
    localStorage.setItem('empresa', empresa);
}

function getPubEmpresa(): string {
    return localStorage.getItem('empresa');
}

function setPubCNPJReg(CNPJreg) {
    localStorage.setItem('CNPJreg', CNPJreg);
}

function getPubCNPJreg(): string {
    return localStorage.getItem('CNPJreg');
}

function setPubUsuarioAlterarEmpresa(usuarioAlterarEmpresa) {
    localStorage.setItem('usuarioAlterarEmpresa', usuarioAlterarEmpresa);
}

function getPubUsuarioAlterarEmpresa(): string {
    return localStorage.getItem('usuarioAlterarEmpresa');
}

function setPubUsuarioVisualizarEmpLog(usuarioVisualizarEmpLog) {
    localStorage.setItem('usuarioVisualizarEmpLog', usuarioVisualizarEmpLog);
}

function getPubUsuarioVisualizarEmpLog(): string {
    return localStorage.getItem('usuarioVisualizarEmpLog');
}

function setPubSistema(sistema) {
    localStorage.setItem('sistema', sistema);
}

function getPubSistema(): string {
    return localStorage.getItem('sistema');
}

function setPubSistemaDescricao(sistemadescricao) {
    localStorage.setItem('sistemadescricao', sistemadescricao);
}

function getPubSistemaDescricao(): string {
    return localStorage.getItem('sistemadescricao');
}

function setPubUpperCase(uppercase: boolean) {
    localStorage.setItem('uppercase', uppercase ? 'S' : 'N');
}

function getPubUpperCase(): boolean {
    return localStorage.getItem('uppercase') === 'S';
}

function setPubClienteRegistro(clienteregistro) {
    localStorage.setItem('clienteregistro', clienteregistro);
}

function getPubClienteRegistro(): string {
    return localStorage.getItem('clienteregistro');
}

function setPubMacAddress(macaddress) {
    localStorage.setItem('macaddress', macaddress);
}

function getPubMacAddress(): string {
    return localStorage.getItem('macaddress');
}

function setPubUsuarioEmail(usuarioemail) {
    localStorage.setItem('usuarioemail', usuarioemail);
}

function getPubUsuarioEmail(): string {
    return localStorage.getItem('usuarioemail');
}

function setPubTipoSistema(tiposistema) {
    localStorage.setItem('tipoSistema', tiposistema);
}

function getTipoSistema(): string {
    return localStorage.getItem('tipoSistema');
}

function getModoLogin(): string {
    return process.env.REACT_APP_MODOLOGIN;
}

function setUsuarioLogado(login: string, email: string, senha: string, nome: string) {
    const dados = { login: login, email: email, senha: senha, nome: nome };
    localStorage.setItem('dadosUsuario', JSON.stringify(dados));
}

function getUsuarioLogado(): string {
    return localStorage.getItem('dadosUsuario');
}

function setEmpresaPadrao(empresa_codigo: string, empresa_razao: string, empresa_cnpj: string) {
    const dados = { empresa_codigo: empresa_codigo, empresa_razao: empresa_razao, empresa_cnpj: empresa_cnpj };
    localStorage.setItem('empresaPadrao', JSON.stringify(dados));
}

function getEmpresaPadrao(): string {
    return localStorage.getItem('empresaPadrao');
}

function getPubProduto(): string {
    return process.env.REACT_APP_PRODUTO;
}

function getGESTAO_SISTEMACODIGO(): String {
    return '0002';
}

function getCRM_SISTEMACODIGO(): String {
    return '0003';
}

function setThemeColor(themeColor) {
    localStorage.setItem('themeColor', themeColor);
}

function getThemeColor(): string {
    return localStorage.getItem('themeColor');
}

export type ErrorRenderPageType = {
    code: string;
    message: string;
};

function setErrorRenderPage(props: ErrorRenderPageType) {
    const dados = { code: props?.code, message: props?.message };
    localStorage.setItem('ErrorRenderPage', JSON?.stringify(dados));
}

function getErrorRenderPage() {
    const storage = localStorage.getItem('ErrorRenderPage');
    let error_page;
    if (storage) {
        error_page = JSON?.parse(localStorage.getItem('ErrorRenderPage'));
    }
    return error_page;
}

function setPeriodoTestes(periodo: string) {
    localStorage.setItem('periodo', periodo);
}

function getPeriodoTestes(): string {
    return localStorage.getItem('periodo');
}

function setPubEmpresaRazao(empresa_razao: string) {
    localStorage.setItem('empresa_razao', empresa_razao);
}

function getPubEmpresaRazao(): string {
    return localStorage.getItem('empresa_razao');
}

function setExibirMsgContaCriadaUNCPIX(exibirMsg: string) {
    localStorage.setItem('exibirMsg', exibirMsg);
}

function getExibirMsgContaCriadaUNCPIX(): string {
    return localStorage.getItem('exibirMsg');
}

function setCasasDecimaisValorUnitarioVenda(value: number) {
    CASASDEC_VALORUNITARIO = value;
}

function getCasasDecimaisValorUnitarioVenda(): number {
    if (CASASDEC_VALORUNITARIO && CASASDEC_VALORUNITARIO > 0) {
        return CASASDEC_VALORUNITARIO;
    } else {
        return CONST_CASASDEC_VALORUNITARIO;
    }
}

const ENTERPRISE = {
    pubUsuario: getPubUsuario,
    pubProduto: getPubProduto,
    pubEmpresa: getPubEmpresa,
    pubCNPJReg: getPubCNPJreg,
    pubUsuarioAlterarEmpresa: getPubUsuarioAlterarEmpresa,
    pubUsuarioVisualizarEmpLog: getPubUsuarioVisualizarEmpLog,
    pubSistema: getPubSistema,
    pubSistemaDescricao: getPubSistemaDescricao,
    pubUpperCase: getPubUpperCase,
    pubClienteRegistro: getPubClienteRegistro,
    pubMacAddress: getPubMacAddress,
    pubUsuarioEmail: getPubUsuarioEmail,
    pubTipoSistema: getTipoSistema,
    pubVersaoSistema: '2024.10.01',
    pubVersaoSistemaBuild: '002',
    SQL_LIMIT: 20,
    pubModoLogin: getModoLogin,
    texto_naodefinido: 'NÃO DEFINIDO',
    tempogratuito: '30',
    periodogratuito: 'Dias',
    pubUsuarioLogado: getUsuarioLogado,
    pubEmpresaPadrao: getEmpresaPadrao,
    pubThemeColor: getThemeColor,
    CRM_SISTEMACODIGO: getCRM_SISTEMACODIGO,
    GESTAO_SISTEMACODIGO: getGESTAO_SISTEMACODIGO,
    getErrorRenderPage: getErrorRenderPage,
    pubPeriodoTestes: getPeriodoTestes,
    pubEmpresaRazao: getPubEmpresaRazao,
    pubExibirMsgContaCriadaUNCPIX: getExibirMsgContaCriadaUNCPIX,
    pubCasasDecimaisValorUnitarioVenda: getCasasDecimaisValorUnitarioVenda,
};

export {
    ENTERPRISE,
    setPubTipoSistema,
    setPubUsuario,
    setPubEmpresa,
    setPubCNPJReg,
    setPubUsuarioAlterarEmpresa,
    setPubUsuarioVisualizarEmpLog,
    setPubSistema,
    getModoLogin,
    setPubSistemaDescricao,
    setPubUpperCase,
    setPubClienteRegistro,
    setPubMacAddress,
    setPubUsuarioEmail,
    setUsuarioLogado,
    setEmpresaPadrao,
    setThemeColor,
    setErrorRenderPage,
    setPeriodoTestes,
    setPubEmpresaRazao,
    setExibirMsgContaCriadaUNCPIX,
    setCasasDecimaisValorUnitarioVenda,
};
